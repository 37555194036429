import React, { useState, useEffect } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import Column from './UIManagement/Column';
import Sidebar from './UIManagement/Sidebar';

const initialColumns = {
  'todo': { id: 'todo', title: 'To Do', cards: [] },
  'doing': { id: 'doing', title: 'Doing', cards: [] },
  'done': { id: 'done', title: 'Done', cards: [] },
};

const ManagementUI = ({ deployment, active_crew_executions, previous_crew_executions, inputs}) => {
  const [columns, setColumns] = useState(initialColumns);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [selectedExecution, setSelectedExecution] = useState(null);

  useEffect(() => {
    setColumns({
      ...initialColumns,
      'doing': {
        ...initialColumns.doing,
        cards: active_crew_executions.map(execution => ({
          id: execution.id,
          content: formatInputsToTags(execution.inputs)
        }))
      },
      'done': {
        ...initialColumns.done,
        cards: previous_crew_executions.map(execution => ({
          id: execution.id,
          content: formatInputsToTags(execution.inputs)
        }))
      }
    });
  }, [active_crew_executions, previous_crew_executions]);

  useEffect(() => {
    // Save data to localStorage
    localStorage.setItem('trelloColumns', JSON.stringify(columns));
  }, [columns]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    // Only allow reordering in the 'todo' column
    if (source.droppableId !== 'todo' || destination.droppableId !== 'todo') return;

    const column = columns[source.droppableId];
    const newCards = Array.from(column.cards);
    const [reorderedCard] = newCards.splice(source.index, 1);
    newCards.splice(destination.index, 0, reorderedCard);

    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        cards: newCards,
      },
    });
  };

  const addCard = (columnId, inputs) => {
    const column = columns[columnId];
    const newCard = { id: Date.now().toString(), content: formatInputsToTags(inputs) };
    setColumns({
      ...columns,
      [columnId]: {
        ...column,
        cards: [...column.cards, newCard],
      },
    });
    setIsAddingCard(false);
  };

  const deleteCard = (columnId, cardId) => {
    const column = columns[columnId];
    const updatedCards = column.cards.filter(card => card.id !== cardId);
    setColumns({
      ...columns,
      [columnId]: {
        ...column,
        cards: updatedCards,
      },
    });
  };

  // Replace the formatInputs function with this new function
  const formatInputsToTags = (inputs) => {
    if (!inputs || Object.keys(inputs).length === 0) return '<div>No inputs</div>';
    return `<div class="flex flex-wrap gap-2">
      ${Object.entries(inputs)
        .map(([key, value]) => `
          <span class="px-3 py-2 rounded-full text-sm font-semibold bg-gray-100 text-black">${key}:
            <span class="px-3 py-1 rounded-full text-sm font-semibold bg-orange-100 text-orange-800">${value}</span>
          </span>
        `)
        .join('')}
    </div>`;
  };

  const fetchExecutionDetails = async (executionId) => {
    try {
      const response = await fetch(`/crewai_plus/management_ui/${deployment.id}/execution/${executionId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSelectedExecution(data);
    } catch (error) {
      console.error('Error fetching execution details:', error);
    }
  };

  const handleCardClick = (executionId) => {
    fetchExecutionDetails(executionId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex flex-col h-screen">
        <div className="flex-1 flex space-x-6 overflow-x-auto p-6">
          {Object.values(columns).map((column) => (
            <Column
              key={column.id}
              column={column}
              deleteCard={deleteCard}
              addCard={addCard}
              isAddingCard={isAddingCard}
              setIsAddingCard={setIsAddingCard}
              isDraggable={column.id === 'todo'}
              availableInputs={inputs}
              deployment={deployment}
              onCardClick={handleCardClick}
            />
          ))}
        </div>
        <Sidebar
          execution={selectedExecution}
          onClose={() => setSelectedExecution(null)}
        />
      </div>
    </DragDropContext>
  );
};

export default ManagementUI;

import React from 'react';
import PropTypes from 'prop-types';
import Message from './UIStudioMessage';
import { updateCrewPlan, generateCrewPlan as generateCrewPlanHelper, executePlan } from '../helpers/CrewPlanHelper';

class UiStudioChat extends React.Component {
  constructor(props) {
    super(props);
    console.log('Props:', props);
    const { formattedMessages, plans } = this.formatInitialMessages(
      props.messages || (props.chat && props.chat.messages) || [
        { html: '<p>Hey there, what are you trying to accomplish and what kind of automation you want to build?</p>', sender: 'ai' }
      ]
    );

    this.state = {
      messages: formattedMessages,
      input: '',
      isLoading: false,
      aiAvatarPath: props.aiAvatarPath,
      chatId: props.chat ? props.chat.id : null,
      messageCount: props.messages ? props.messages.length : 1,
      isWaitingForResponse: false,
      plans: plans,
      isGeneratingResponse: false,
      planGenerated: false,
    };

    this.chatRef = React.createRef();

    // Bind methods
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.updatePlan = this.updatePlan.bind(this);
    this.generateCrewPlan = this.generateCrewPlan.bind(this);
    this.executePlan = this.executePlan.bind(this);
    this.exploreTemplates = this.exploreTemplates.bind(this);
    this.improveExistingProcess = this.improveExistingProcess.bind(this);
    this.optimizeBusinessMetrics = this.optimizeBusinessMetrics.bind(this);
    this.handleCellUpdate = this.handleCellUpdate.bind(this);
    this.attachCellUpdateListeners = this.attachCellUpdateListeners.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  handleInputChange(e) {
    this.setState({ input: e.target.value });
  }

  formatInitialMessages(messages) {
    const plans = [];
    const formattedMessages = messages.map(message => {
      if (message.sender === 'ai' && message.plan && message.plan.id) {
        let formattedHtml = message.html ? `<p>${message.html}</p>` : '';
        const plan = message.plan;
        try {
          formattedHtml += this.createToolUsageTable(plan.plan, plan.id);
          plans.push(plan);
        } catch (e) {
          console.log('Error parsing tool usage arguments:', e);
        }
        console.log('=====================');
        console.log('Message:', message);
        return { html: formattedHtml, sender: 'ai', crewPlan: !!message.plan };
      }
      return message;
    });

    return { formattedMessages, plans };
  }

  componentDidMount() {
    this.scrollToBottom();
    this.attachCellUpdateListeners();
    window.executePlan = this.executePlan.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // If messages or plans have changed, re-attach the event listeners
    if (
      prevState.messages !== this.state.messages ||
      prevState.plans !== this.state.plans
    ) {
      this.attachCellUpdateListeners();
    }
  }

  componentWillUnmount() {
    delete window.executePlan;
  }

  handleKeyPress(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      this.sendMessage(e);
    }
  }

  sendMessage(e) {
    e.preventDefault();
    if (this.state.input.trim() === '' || this.state.isWaitingForResponse) return;

    const userMessage = this.state.input.trim();
    const newMessage = { text: userMessage, sender: 'user' };
    this.setState(prevState => ({
      messages: [...prevState.messages, newMessage],
      input: '',
      isLoading: true,
      isWaitingForResponse: true,
      isGeneratingResponse: true,
      messageCount: prevState.messageCount + 1,
      planGenerated: false  // Reset this when a new message is sent
    }), () => {
      this.getAIResponse(userMessage);
      this.scrollToBottom();
    });
  }

  getAIResponse(message) {
    fetch('/crewai_plus/ui_studio/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ message: message, chat_id: this.state.chatId })
    })
    .then(response => response.json())
    .then(data => {
      let aiMessageHtml = data.response;

      if (data.plan) {
        const newPlan = data.plan;
        aiMessageHtml += this.createToolUsageTable(newPlan.function.name, args, newPlan.plan_id);
      }

      const aiMessage = { html: aiMessageHtml, sender: 'ai' };
      this.setState(prevState => ({
        messages: [...prevState.messages, aiMessage],
        chatId: data.chat_id || prevState.chatId,
        messageCount: prevState.messageCount + 1,
        isGeneratingResponse: false
      }), this.scrollToBottom);

      if (!window.location.pathname.includes(data.chat_id)) {
        const newUrl = `${window.location.pathname}${window.location.pathname.endsWith('/') ? '' : '/'}${data.chat_id}`;
        window.history.pushState({ chatId: data.chat_id }, '', newUrl);
      }
    })
    .catch(error => {
      console.error('Error:', error);
      const errorMessage = { html: "<p>Sorry, there was an error processing your request.</p>", sender: 'ai' };
      this.setState(prevState => ({
        messages: [...prevState.messages, errorMessage],
        isGeneratingResponse: false
      }), this.scrollToBottom);
    })
    .finally(() => {
      this.setState({ isLoading: false, isWaitingForResponse: false });
    });
  }

  createToolUsageTable(plan, planId) {
    if (!plan || !plan.agents || !plan.tasks) return '';
    const createTable = (title, data, headers) => `
      <div class="mt-4 overflow-hidden rounded-lg shadow">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              ${headers.map(header => `<th class="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">${header}</th>`).join('')}
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            ${data.map((row, rowIndex) => `
              <tr>
                ${headers.map((header, cellIndex) => {
                  const field = header.toLowerCase().replace(' ', '_');
                  const value = row[field] || '';
                  const isRoleField = (title === 'Agents' && field === 'role') || (title === 'Tasks' && field === 'agent_role');
                  return `
                    <td class="px-6 py-2 whitespace-normal text-sm text-gray-500">
                      <div class="relative group">
                        <div contenteditable="true"
                             class="outline-none focus:ring-2 focus:ring-primary-color rounded p-1 hover:bg-gray-100 transition-colors duration-200 cursor-text pr-6"
                             data-row-index="${rowIndex}"
                             data-field="${field}"
                             data-section="${title.toLowerCase()}"
                             data-plan-id="${planId}"
                             ${isRoleField ? `data-agent-role="${value}"` : ''}
                             data-cell-update>
                          ${value}
                        </div>
                        <svg class="w-4 h-4 text-gray-400 absolute top-1/2 right-1 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                      </div>
                    </td>
                  `;
                }).join('')}
              </tr>
            `).join('')}
          </tbody>
        </table>
      </div>
    `;


    const agentsTable = createTable('Agents', plan.agents, ['Role', 'Goal', 'Backstory']);
    const tasksTable = createTable('Tasks', plan.tasks, ['Description', 'Expected Output', 'Agent Role']);

    return `
      <div class="w-full">
        <div class="">
          <h4 class="text-md leading-6 font-bold text-gray-900">Agents</h4>
          ${agentsTable}
        </div>
        <div class="">
          <h4 class="text-md leading-6 font-bold text-gray-900">Tasks</h4>
          ${tasksTable}
        </div>
        <div class="mt-4">
          <button id="createCrewBtn-${planId}"
                  class="px-4 py-3 w-full bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300 flex items-center justify-center"
                  onclick="window.executePlan('${planId}')">
            <span>Create Crew</span>
          </button>
        </div>
      </div>
    `;
  }

  handleCellUpdate(event) {
    const element = event.target;
    const planId = element.getAttribute('data-plan-id');
    const section = element.getAttribute('data-section');
    const rowIndex = parseInt(element.getAttribute('data-row-index'));
    const field = element.getAttribute('data-field');
    const newValue = element.textContent.trim();

    console.log(`Updating ${section} - ${field}: ${newValue}`);

    const oldValue = element.getAttribute('data-agent-role') || element.textContent.trim();

    this.setState(prevState => {
      const plans = [...prevState.plans];
      const planIndex = plans.findIndex(plan => plan.id == planId);
      if (planIndex === -1) return null;

      // Deep copy the plan to avoid mutating state directly
      const plan = JSON.parse(JSON.stringify(plans[planIndex]));

      // Update the specific field
      plan.plan[section][rowIndex][field] = newValue;

      if ((section === 'agents' && field === 'role') || (section === 'tasks' && field === 'agent_role')) {
        if (oldValue !== newValue) {
          if (section === 'agents') {
            // Update tasks that reference the old role
            plan.plan.tasks = plan.plan.tasks.map(task => {
              if (task.agent_role === oldValue) {
                task.agent_role = newValue;
              }
              return task;
            });
          } else if (section === 'tasks') {
            // Update agents that have the old role
            plan.plan.agents = plan.plan.agents.map(agent => {
              if (agent.role === oldValue) {
                agent.role = newValue;
              }
              return agent;
            });
            // Update other tasks with the same old role
            plan.plan.tasks = plan.plan.tasks.map((task, idx) => {
              if (task.agent_role === oldValue && idx !== rowIndex) {
                task.agent_role = newValue;
              }
              return task;
            });
          }

          // Update the DOM elements' data attributes and text content
          document.querySelectorAll('[data-agent-role]').forEach(el => {
            if (el.getAttribute('data-agent-role') === oldValue) {
              el.setAttribute('data-agent-role', newValue);
              if (el.getAttribute('data-field') === 'agent_role' || el.getAttribute('data-field') === 'role') {
                el.textContent = newValue;
              }
            }
          });

          this.notifyRoleChange(oldValue, newValue);
        }
      }

      plans[planIndex] = plan;

      // Call updatePlan with the updated plan
      this.updatePlan(planId, plan.plan);

      return { plans };
    });
  }

  notifyRoleChange(oldRole, newRole) {
    const message = `Agent role "${oldRole}" has been updated to "${newRole}" across all references.`;
    console.log(message);
    // Implement a user-friendly notification here, e.g., a toast message
  }

  attachCellUpdateListeners() {
    document.querySelectorAll('[data-cell-update]').forEach(element => {
      element.removeEventListener('blur', this.handleCellUpdate);
      element.addEventListener('blur', this.handleCellUpdate);
    });
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.chatRef.current) {
        this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
      }
    }, 0);
  }

  async generateCrewPlan() {
    if (this.state.isWaitingForResponse) return;

    this.setState({
      isLoading: true,
      isWaitingForResponse: true,
      isGeneratingResponse: true
    });

    try {
      const data = await generateCrewPlanHelper(this.state.chatId);
      let crewPlanHtml = data.response;
      let newPlan = data.plan;

      if (newPlan && newPlan.plan) {
        const processedPlan = {
          ...newPlan,
          plan: {
            agents: newPlan.plan.agents.map(agent => ({
              ...agent,
              id: Math.random().toString(36).substr(2, 9)
            })),
            tasks: newPlan.plan.tasks.map(task => {
              const matchingAgent = newPlan.plan.agents.find(agent => agent.role === task.agent_role);
              return {
                ...task,
                agentId: matchingAgent ? matchingAgent.id : null
              };
            })
          }
        };

        try {
          crewPlanHtml += this.createToolUsageTable(processedPlan.plan, processedPlan.id);
        } catch (e) {
          console.error('Error parsing tool usage arguments:', e);
        }

        const crewPlanMessage = { html: crewPlanHtml, sender: 'ai', crewPlan: true };
        this.setState(prevState => ({
          messages: [...prevState.messages, crewPlanMessage],
          messageCount: prevState.messageCount + 1,
          plans: [...prevState.plans, processedPlan],
          planGenerated: true  // Set this to true when a plan is generated
        }), this.scrollToBottom);
      } else {
        throw new Error('Invalid plan data received');
      }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { html: "<p>Sorry, there was an error generating the crew plan.</p>", sender: 'ai' };
      this.setState(prevState => ({
        messages: [...prevState.messages, errorMessage]
      }), this.scrollToBottom);
    } finally {
      this.setState({
        isLoading: false,
        isWaitingForResponse: false,
        isGeneratingResponse: false  // Add this line
      }, this.scrollToBottom);
    }
  }

  async updatePlan(planId, updatedPlan){
    try {
      const data = await updateCrewPlan(this.state.chatId, planId, updatedPlan);
      console.log('Plan updated successfully:', data);
    } catch (error) {
      console.error('Error updating plan:', error);
      // Consider adding error handling here, such as showing an error message to the user
    }
  }

  async executePlan(planId){
    if (this.state.isWaitingForResponse) return;

    this.setState({ isLoading: true, isWaitingForResponse: true });

    // Update the button state immediately
    const button = document.getElementById(`createCrewBtn-${planId}`);
    button.disabled = true;
    button.classList.add('opacity-50', 'cursor-not-allowed');
    button.innerHTML = `
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      Creating...
    `;

    try {
      const data = await executePlan(this.state.chatId, planId);
      if (data.crew.id) {
        window.location.href = `/crewai_plus/ui_studio/ui_crews/${data.crew.id}`;
      } else {
        const executionMessage = { html: `<p>${data.message}</p>`, sender: 'ai' };
        this.setState(prevState => ({
          messages: [...prevState.messages, executionMessage],
          messageCount: prevState.messageCount + 1
        }), this.scrollToBottom);
      }
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { html: "<p>Sorry, there was an error executing the crew plan.</p>", sender: 'ai' };
      this.setState(prevState => ({
        messages: [...prevState.messages, errorMessage]
      }), this.scrollToBottom);

      // Reset the button state on error
      button.disabled = false;
      button.classList.remove('opacity-50', 'cursor-not-allowed');
      button.textContent = 'Create Crew';
    } finally {
      this.setState({ isLoading: false, isWaitingForResponse: false }, this.scrollToBottom);
    }
  }

  exploreTemplates() {
    // TODO: Implement explore templates
    // 1. Get list of templates
    // 2. Display list of templates
    // 3. Allow user to select template
    // 4. Generate crew plan
    console.log('Explore Templates');
  }

  improveExistingProcess() {
    const userMessage = "Can you help me improve an existing business process? What information do you need?";
    const newMessage = { text: userMessage, sender: 'user' };
    this.setState(prevState => ({
      messages: [...prevState.messages, newMessage],
      input: '',
      isLoading: true,
      isWaitingForResponse: true,
      messageCount: prevState.messageCount + 1
    }), () => {
      this.getAIResponse(userMessage);
      this.scrollToBottom();
    });
  }

  optimizeBusinessMetrics() {
    const userMessage = "Can you help me optimize my business metrics? What information do you need to help me do that?";
    const newMessage = { text: userMessage, sender: 'user' };
    this.setState(prevState => ({
      messages: [...prevState.messages, newMessage],
      input: '',
      isLoading: true,
      isWaitingForResponse: true,
      messageCount: prevState.messageCount + 1
    }), () => {
      this.getAIResponse(userMessage);
      this.scrollToBottom();
    });
  }

  displayInitialOptions() {
    return(
      <div className="px-2 mb-2 flex space-x-2">
        <button
          onClick={this.exploreTemplates}
          className={`w-full px-4 py-2 bg-gray-400 text-sm text-white rounded-lg hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
        >
          Explore Templates
        </button>
        <button
          onClick={this.improveExistingProcess}
          className={`w-full px-4 py-2 bg-gray-400 text-sm text-white rounded-lg hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
        >
          Improve an Existing Process
        </button>
        <button
          onClick={this.optimizeBusinessMetrics}
          className={`w-full px-4 py-2 bg-gray-400 text-sm text-white rounded-lg hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2`}
        >
          Optimize Business Metrics
        </button>
      </div>
    )
  }

  displayPlanGenerationButton() {
    const { isWaitingForResponse, planGenerated, messageCount } = this.state;

    // Only display the button if a plan hasn't been generated and there are at least 5 messages
    if (planGenerated || messageCount < 5) {
      return null;
    }

    return (
      <div className="px-2 mb-2">
        <button
          onClick={this.generateCrewPlan}
          disabled={isWaitingForResponse}
          className={`w-full px-4 py-2 bg-primary-color text-sm text-white rounded-lg hover:bg-primary-color-dark focus:outline-none focus:ring-2 focus:ring-primary-color focus:ring-offset-2 ${isWaitingForResponse ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isWaitingForResponse ? 'Generating...' : 'Generate Crew Plan'}
        </button>
      </div>
    );
  }

  render() {
    const { messages, input, isLoading, messageCount, isWaitingForResponse, aiAvatarPath, isGeneratingResponse } = this.state;
    return (
      <ul role="list" className="rid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8 w-full h-full">
        <div className='h-full'>
          <li className="overflow-hidden rounded-xl border border-gray-200">
            <div className='flex flex-col' style={{ "height": "95dvh" }}>
              <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-4 py-3 justify-between">
                <div className="flex items-center">
                  <div className="text-sm font-medium leading-6 text-gray-900 ml-3">
                    Configure your Crew
                  </div>
                </div>
              </div>

              <div className="overflow-y-auto p-2" ref={this.chatRef}>
                {messages && messages.map((message, index) => (
                  <Message key={index} message={message} aiAvatarPath={aiAvatarPath} />
                ))}
                {isGeneratingResponse && (
                  <div className="flex items-center justify-center space-x-2 text-gray-500 italic p-3">
                      <svg className="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span className='text-sm animate-pulse'>Thinking...</span>
                  </div>
                )}
              </div>

              {messageCount == 1 && (
                // this.displayInitialOptions()
                true
              )}

              {this.displayPlanGenerationButton()}

              <form onSubmit={this.sendMessage} className="mt-auto mb-2 flex px-2">
                <textarea
                  value={input}
                  onChange={this.handleInputChange}
                  onKeyPress={this.handleKeyPress}
                  disabled={isWaitingForResponse}
                  className={`text-sm w-4/5 mr-4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-color focus:border-primary-color ${isWaitingForResponse ? 'opacity-50 cursor-not-allowed' : ''}`}
                  rows="4"
                  placeholder="Describe what you are trying to accomplish and what kind of automation you want to build."
                />
                <button
                  type="submit"
                  disabled={isLoading || isWaitingForResponse}
                  className={`w-1/5 px-4 py-2 !bg-primary-color text-sm text-white rounded-lg hover:!bg-primary-color-dark focus:outline-none focus:ring-2 focus:ring-primary-color focus:ring-offset-2 ${(isLoading || isWaitingForResponse) ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {isWaitingForResponse ? 'Waiting...' : (isLoading ? 'Sending...' : 'Send')}
                </button>
              </form>
            </div>
          </li>
        </div>
      </ul>
    );
  }
}

UiStudioChat.propTypes = {
  chat: PropTypes.shape({
    id: PropTypes.number,
    messages: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      html: PropTypes.string,
      sender: PropTypes.oneOf(['ai', 'user']),
      tool_calls: PropTypes.array
    }))
  }),
  messages: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string,
    sender: PropTypes.oneOf(['ai', 'user']),
    tool_calls: PropTypes.array
  }))
};

export default UiStudioChat;
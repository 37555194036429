import React, { useState } from 'react';

const AddCardForm = ({ onAddCard, onCancel, availableInputs, deployment }) => {
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`/crewai_plus/management_ui/${deployment.id}/kickoff`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ inputs: inputs }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      await response.json();

      onAddCard(inputs);

    } catch (error) {
      console.error('Error kicking off crew:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-2">
      <div className="bg-white rounded-lg shadow-md p-4">
        <h2 className="text-xl mb-4 text-gray-800">Launch new Crew</h2>
        <div className="space-y-4">
          {availableInputs.map((input, index) => (
            <div key={index} className="flex flex-col">
              <label htmlFor={input} className="mb-1 font-medium text-gray-700">
                {input}
              </label>
              <input
                type="text"
                id={input}
                name={input}
                value={inputs[input] || ''}
                onChange={(e) => setInputs({ ...inputs, [input]: e.target.value })}
                className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder={`Enter ${input}`}
                required
              />
            </div>
          ))}
        </div>
        <div className="mt-2 flex justify-end space-x-2">
          <button
            onClick={onCancel}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            className="bg-primary-color hover:bg-primary-color-dark text-white font-bold py-2 px-4 rounded transition-colors duration-200"
          >
            {isLoading ? 'Creating...' : 'Create Crew'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddCardForm;

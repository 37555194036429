import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "spinner", "statusSelect"]
  static values = { deploymentId: String }

  connect() {
    this.loadCrewExecutions()
  }

  loadCrewExecutions(event) {
    if (event) event.preventDefault()
    const status = this.statusSelectTarget.value
    let url = `/crewai_plus/deployments/${this.deploymentIdValue}/crew_executions?status=${status}`

    if (event && event.currentTarget.href) {
      url = event.currentTarget.href
    }

    this.showSpinner()

    fetch(url, {
      headers: {
        'Accept': 'text/html',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => response.text())
      .then(html => {
        this.containerTarget.innerHTML = html
      })
      .catch(error => console.error("Error loading crew executions:", error))
      .finally(() => this.hideSpinner())
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
    this.containerTarget.style.opacity = '0.3'
    this.containerTarget.style.pointerEvents = 'none'
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('hidden')
    this.containerTarget.style.opacity = '1'
    this.containerTarget.style.pointerEvents = 'auto'
  }
}

import React, { useState, useEffect, useRef } from 'react';

const Accordion = ({ title, children, copyable = false, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    setShowCopyNotification(true);
    setTimeout(() => setShowCopyNotification(false), 1500); 
  };

  return (
    <div className="border-b border-gray-200 relative">
      <button
        className="flex justify-between items-center w-full py-4 px-2 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-semibold">{title}</span>
        <div className="flex items-center">
          {copyable && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleCopy();
              }}
              className="mr-2 text-gray-500 hover:text-gray-700"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184" />
              </svg>
            </button>
          )}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d={isOpen ? "m19.5 8.25-7.5 7.5-7.5-7.5" : "m8.25 4.5 7.5 7.5-7.5 7.5"} />
          </svg>
        </div>
      </button>
      {isOpen && <div className="p-4 bg-gray-50">{children}</div>}
      {showCopyNotification && (
        <div className="absolute top-0 right-0 mt-12 mr-2 bg-gray-800 text-white px-3 py-1 rounded-md text-sm">
          Copied!
        </div>
      )}
    </div>
  );
};

const Sidebar = ({ execution, onClose }) => {
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!execution) return null;

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatInputsToTags = (inputs) => {
    if (!inputs || Object.keys(inputs).length === 0) return 'No inputs';
    return Object.entries(inputs).map(([key, value]) => (
      <span key={key} className="inline-block px-3 py-1 mr-2 mb-2 rounded-full text-sm font-semibold bg-gray-100 text-gray-800">
        {key}: <span className="bg-orange-100 text-orange-800 px-2 py-1 rounded-full">{value}</span>
      </span>
    ));
  };

  return (
    <div ref={sidebarRef} className="fixed right-0 top-0 h-full w-1/3 bg-white shadow-lg p-6 overflow-y-auto">
      <div className="sticky top-0 bg-white z-10 p-6 border-b border-gray-200">
        <div className="grid grid-cols-[1fr,auto] items-start gap-4">
          <div>
            <h2 className="text-2xl font-bold">Execution Details</h2>
            <p className="text-sm text-gray-500 mt-1">ID: {execution.id}</p>
          </div>
          <div className="flex flex-col items-end">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 mt-2"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      
      <div className="p-6">
        <div className="mb-6">
          <h3 className="text-sm font-semibold text-gray-500 uppercase">Inputs</h3>
          <div className="mt-2 flex flex-wrap">
            {formatInputsToTags(execution.inputs)}
          </div>
        </div>

        {execution.metrics && Object.keys(execution.metrics).length > 0 && (
          <div className="mb-6">
            <h3 className="text-sm font-semibold text-gray-500 uppercase mb-2">Metrics</h3>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(execution.metrics).map(([key, value]) => (
                <div key={key} className="bg-red-50 p-3 rounded-lg">
                  <p className="text-xs text-primary-color-dark font-medium">{key.replace(/_/g, ' ').toUpperCase()}</p>
                  <p className="text-lg font-semibold text-primary-color-dark">{value}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {execution.output && (
          <Accordion 
            title="Output" 
            copyable={true} 
            content={execution.output}
          >
            <div className="whitespace-pre-wrap text-sm">{execution.output}</div>
          </Accordion>
        )}

        <Accordion title="Tasks">
          {execution.tasks.map((task, index) => (
            <div key={task.id} className="mb-4 last:mb-0">
              <h4 className="font-semibold">Task {index + 1}</h4>
              <p className="text-sm"><strong>Description:</strong> {task.formatted_description}</p>
              <p className="text-sm"><strong>Status:</strong> {task.status}</p>
              {task.output && (
                <Accordion title="Output">
                  <pre className="whitespace-pre-wrap text-sm">{task.output}</pre>
                </Accordion>
              )}
            </div>
          ))}
        </Accordion>

        <div className="mt-6 text-sm text-gray-500">
          <p>Created: {formatDate(execution.created_at)}</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

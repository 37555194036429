// components/Card.jsx
import React from 'react';
import { Draggable } from '@hello-pangea/dnd';

const Card = ({ card, index, deleteCard, isDraggable, onClick, columnId }) => {
  const cardContent = (
    <div 
      className="bg-white shadow-md rounded-lg p-4 mb-3 transition-all duration-200 hover:shadow-lg cursor-pointer"
      onClick={() => onClick(card.id)}
    >
      <div className="flex justify-between items-center">
        <div dangerouslySetInnerHTML={{ __html: card.content }} />
        {columnId === 'doing' && (
          <div className="ml-2 animate-spin">
            <svg className="w-5 h-5 text-primary-color" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );

  if (!isDraggable) {
    return cardContent;
  }

  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {cardContent}
        </div>
      )}
    </Draggable>
  );
};

export default Card;
